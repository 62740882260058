import {
  FormControlLabel,
  SxProps,
  type FormControlLabelProps,
  Checkbox
} from '@mui/material'
import React, { forwardRef } from 'react'

import { ICustomInput } from 'features/formConstructor/types'

interface IProps {
  input: ICustomInput;
  styles?: SxProps;
}

export type TCustomCheckboxInputProps = IProps & Omit<FormControlLabelProps, 'control' | 'label'>;

export const CustomCheckboxInput = forwardRef<
  HTMLDivElement,
  TCustomCheckboxInputProps
>(({ input, styles, ...props }, ref) => {
  const { question } = input

  return (
    <FormControlLabel {...props} control={<Checkbox />} label={question} ref={ref} />
  )
})

CustomCheckboxInput.displayName = 'CustomCheckboxInput'
