export const commonDataFieldNames = {
  first_name: 'firstName',
  last_name: 'lastName',
  other_name: 'middleName',
  birth_date: 'birthDay',
  gender: 'sex'
}
export const documentsDataFieldNames = {
  identify_document_code: 'number',
  identify_document_issued_date: 'dateIssue',
  identify_document_issued_by: 'issue',
  social_number: 'inn'
}
