// import { ITemplate } from 'redux/reducers/constructors/formConstructor/types';

export const mockResponse = [
  {
    id: 911,
    selected_template: {
      id: 1,
      q_uuid: 'd34b3aea-a914-4ede-8126-425dc926e42b',
      q_type: 'individual',
      title: 'Анкета кредиту до зарплати',
      elements: {
        id: 'd34b3aea-a914-4ede-8126-425dc926e42b',
        type: 'individual',
        steps: [
          {
            id: 'constructor-7d63d48e-7f1b-4252-92a9-de5d01bfdb98',
            name: 'персональні_дані',
            title: 'Персональні дані',
            blocks: [
              {
                id: 'aac9d5ed-5cf1-4b73-9d42-5c2dbc2349cc',
                title: 'ПІБ, дата народження, сімейний стан',
                inputs: [
                  {
                    id: '1d62910f-e24c-4668-9c37-048a04b6edee',
                    name: 'first_name',
                    type: 'text',
                    styles: {
                      size: 12,
                      order: '2'
                    },
                    options: [],
                    question: "Ім'я",
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'e88ce78d-f77b-44e9-9da9-f7840c517349',
                    name: 'last_name',
                    type: 'text',
                    styles: {
                      size: 12,
                      order: '1'
                    },
                    question: 'Прізвище',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '0c73524c-8d71-4145-bbf1-694a01b9242f',
                    name: 'birth_date',
                    type: 'date',
                    styles: {
                      size: 12,
                      order: '4'
                    },
                    question: 'Дата народження',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '0fce65ad-aac6-4fe1-8988-dc1dfb6a40db',
                    name: 'gender',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: '4'
                    },
                    options: [
                      'Male',
                      'Female'
                    ],
                    question: 'Стать',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '34b47088-0a3b-48a7-98e8-7d022b031532',
                    name: 'marital_status',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: '4'
                    },
                    options: [
                      'Single',
                      'Married',
                      'Divorced',
                      'Civil marriage',
                      'Widow(er)'
                    ],
                    question: 'Сімейний стан',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '81e6b2fe-dc15-4cd1-8b32-8500ac0dac66',
                    name: 'number_of_children_under_18',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: '4'
                    },
                    options: [],
                    question: 'Кількість дітей до 18 років',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'ef8d5524-6bc0-4d6c-8d11-2a12af241e87',
                    name: 'other_name',
                    type: 'text',
                    styles: {
                      size: 12,
                      order: '3'
                    },
                    options: [],
                    question: 'По батькові',
                    isMandatoryQuestion: true
                  }
                ]
              },
              {
                id: '38bbbbfb-fcfb-49f4-94df-32201fe260bd',
                title: 'Реєстраційні документи',
                inputs: [
                  {
                    id: 'a3a4641a-e2a9-411c-b028-78902a0016cd',
                    name: 'identify_document_code',
                    type: 'text',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [],
                    question: 'Номер та серія паспорту (8 символів) або ІД картки (9 цифр)',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '54b02750-bb7a-4db2-866b-d27493b422e8',
                    name: 'identify_document_issued_by',
                    type: 'text',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Ким виданий',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'd02acb1e-186d-457b-b4ca-fab931418da3',
                    name: 'identify_document_issued_date',
                    type: 'date',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Коли виданий',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '9b8ae232-4d1b-49f5-8265-faf06cdf9939',
                    name: 'social_number',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'ІПН',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '2b5af841-38f5-4baf-8dd1-217d15f17b62',
                    name: 'select',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [
                      'Ukrainian',
                      'Russian',
                      'Romanian',
                      'Moldovan',
                      'Belarusian',
                      'Crimean Tatar',
                      'Bulgarian',
                      'Hungarian',
                      'Polish',
                      'Jewish',
                      'Pontic Greeks',
                      'Other'
                    ],
                    question: 'Національність',
                    isMandatoryQuestion: true
                  }
                ]
              },
              {
                id: '86c3eba5-abbc-4559-b638-2c7b7b08b289',
                title: 'Контактна інформація',
                inputs: [
                  {
                    id: '4cce0301-be7c-4918-82dd-81cd4a2eced1',
                    name: 'phone_number',
                    type: 'phone',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Телефон',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '6f6e8158-ec1c-46a8-8ea5-f7b5695f4ba7',
                    name: 'email',
                    type: 'email',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Email',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'f598c2d3-af8a-482e-b0aa-0ef667c889f6',
                    name: 'city',
                    type: 'text',
                    styles: {
                      size: 3,
                      order: 1
                    },
                    question: 'Населений пункт',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '0f7e88bf-525d-4f8c-8e72-0765cc9eb175',
                    name: 'city_type',
                    type: 'select',
                    styles: {
                      size: 3,
                      order: 1
                    },
                    options: [
                      'Місто',
                      'СМТ',
                      'Село'
                    ],
                    question: 'Тип населеного пункту',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'bd1e2898-964e-4e58-af85-89e3e1691d91',
                    name: 'state',
                    type: 'select',
                    styles: {
                      size: 6,
                      order: 1
                    },
                    options: [
                      'Вінницька',
                      'Волинська',
                      'Дніпропетровська',
                      'Донецька',
                      'Житомирська',
                      'Закарпатська',
                      'Запорізька',
                      'Івано-Франківська',
                      'Київська',
                      'Кіровоградська',
                      'Луганська',
                      'Львівська',
                      'Миколаївська',
                      'Одеська',
                      'Полтавська',
                      'Рівненська',
                      'Сумська',
                      'Тернопільська',
                      'Харківська',
                      'Херсонська',
                      'Хмельницька',
                      'Черкаська',
                      'Чернівецька',
                      'Чернігівська',
                      'АР Крим'
                    ],
                    question: 'Область',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '20a79476-ee18-48b0-957e-0d1e2b44f135',
                    name: 'street',
                    type: 'text',
                    styles: {
                      size: 6,
                      order: 1
                    },
                    question: 'Вулиця',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'c7c6348e-92c0-49a0-8c61-4f5d989d4d76',
                    name: 'index',
                    type: 'text',
                    styles: {
                      size: 6,
                      order: 1
                    },
                    question: 'Індекс',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'f840b80b-8583-4a49-b544-17313415bbbe',
                    name: 'house',
                    type: 'text',
                    styles: {
                      size: 3,
                      order: 1
                    },
                    question: 'Будинок',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '66c21899-ce58-4c32-8306-f230c585b7ff',
                    name: 'korpus',
                    type: 'text',
                    styles: {
                      size: 3,
                      order: 1
                    },
                    question: 'Корпус',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '039f5176-baf3-4b37-a338-0303182218bb',
                    name: 'flat',
                    type: 'text',
                    styles: {
                      size: 3,
                      order: 1
                    },
                    question: 'Квартира',
                    isMandatoryQuestion: true
                  }
                ]
              },
              {
                id: '8776716b-0c9e-4245-82e9-f11293342478',
                title: 'Зайнятість',
                inputs: [
                  {
                    id: '6a876914-1c02-41a5-897b-c5e84d2fc661',
                    name: 'employment_type',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [
                      'Fulltime',
                      'Partial',
                      'Freelance',
                      'Temporary',
                      'Unemployed',
                      'Retired',
                      'Student',
                      'Maternity leave',
                      'Self-employed',
                      'Unemployed'
                    ],
                    question: 'Тип зайнятості',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'eaaccdf8-f968-4d4e-a1e7-572ef439de0f',
                    name: 'position',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [
                      'None',
                      'Worker',
                      'Military',
                      'Specialist',
                      'Middle manager',
                      'Senior manager',
                      'Owner or co-owner',
                      'Other'
                    ],
                    question: 'Посада',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'fd927840-a1e3-411f-af60-ea21827f361f',
                    name: 'employment_start_date',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Тривалість праці на останнньому місці (міс.)',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '0affbdbc-9274-4d05-929a-6136edbd38d6',
                    name: 'seniority_years',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Трудовий стаж (років)',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'a25817f3-d890-4625-ac0d-6fa00726f96f',
                    name: 'education',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [
                      'Incomplete secondary',
                      'Secondary',
                      'Secondary-special',
                      'Incomplete higher',
                      'Higher',
                      'Second higher',
                      'MBA'
                    ],
                    question: 'Освіта',
                    isMandatoryQuestion: true
                  },
                  {
                    id: 'b541fdda-9503-417b-9e90-7ee70cbf9514',
                    name: 'employees_count',
                    type: 'select',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [
                      '1-10',
                      '10-100',
                      '100-500',
                      'More than 500'
                    ],
                    question: 'Кількість співробітників',
                    isMandatoryQuestion: true
                  }
                ]
              }
            ]
          },

          {
            id: 'constructor-f0f667b7-9df9-4b27-a958-5fe83d2b6bf1',
            name: 'фінансовий_стан',
            title: 'Фінансовий стан',
            blocks: [
              {
                id: 'cae04d2f-0bef-4834-ae5e-14d9eeefb9b8',
                title: 'Регулярний дохід, грн.',
                inputs: [
                  {
                    id: 'b9dace5e-f56f-464e-b74b-b645ece8f0bf',
                    name: 'net_income',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    options: [],
                    question: 'Місячна заробітна плата або дохід від підприємницької діяльності',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '9462462b-647f-434a-8682-3526bbe0224a',
                    name: 'щомісячний_дохід_від_надання_майна_в_оренду',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Щомісячний дохід від надання майна в оренду',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '1b068240-aa5e-4f94-bf26-a744cdd53347',
                    name: 'соціальні_виплати,_що_отримуються_на_регулярній_основі',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Соціальні виплати, що отримуються на регулярній основі',
                    isMandatoryQuestion: true
                  }
                ]
              },
              {
                id: 'e863985c-0c95-4367-8ec8-b42f220abb77',
                title: 'Нерегулярний дохід, грн.',
                inputs: [
                  {
                    id: '87746467-f3f3-40fc-9958-c8b7c250f4d8',
                    name: 'разові_доходи_за_останній_рік_від_продажу_майна',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Разові доходи за останній рік від продажу майна',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '3bdfc2a7-617e-4cb9-9e45-b4b6d3eef87f',
                    name: 'проценти_за_депозитними_вкладами,_цінними_паперами_(в_середньому_за_місяць_за_останні_три_місяці)',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Проценти за депозитними вкладами, цінними паперами (в середньому за місяць за останні три місяці)',
                    isMandatoryQuestion: true
                  }
                ]
              },
              {
                id: '9ee6cc33-4a8d-4774-8fc8-843372e77566',
                title: 'Витрати, грн.',
                inputs: [
                  {
                    id: 'f092242e-7285-46a8-8123-a9f420147a26',
                    name: 'сума_щомісячних_витрат_з_власного_доходу_на_іжу,_одяг_та_комунальні_послуги',
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: 'Сума щомісячних витрат з власного доходу на іжу, одяг та комунальні послуги',
                    isMandatoryQuestion: true
                  },
                  {
                    id: '19ddcc2d-fb64-439b-80f3-8fb18cebb30d',
                    name: "сума_щомісячних_витрат_з_власного_доходу_на_покриття_фінансових_зобов'язань",
                    type: 'number',
                    styles: {
                      size: 12,
                      order: 1
                    },
                    question: "Сума щомісячних витрат з власного доходу на покриття фінансових зобов'язань",
                    isMandatoryQuestion: true
                  }
                ]
              }
            ]
          }
        ],
        credit_policy: 4
      },
      credit_policy: 4,
      creator: 2164,
      added_questions: []
    },
    filled_template: null
  }
]

export const mockBankIdData = {
  type: 'physical',
  inn: '3819404279',
  sex: 'M',
  email: 'n/a',
  birthDay: '27.07.2004',
  birthPlace: 'UA ПІДВИСОКЕ',
  nationality: 'UA',
  firstName: 'Стас',
  lastName: 'Куций',
  middleName: 'Володимирович',
  documents: [
    {
      type: 'idpassport',
      typeName: 'ПАСПОРТ ГРОМАДЯНИНА УКРАЇНИ (ID КАРТКА)',
      series: 'KM',
      number: '123456789',
      dateIssue: '12.02.2015',
      issue: '4312'
    }]
}
