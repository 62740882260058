import {
  IApplicationBankIdData,
  IApplicationPayment,
  IApplicationRepaymentChange,
  IApplicationWorkflow,
  ILoanHistoryApp,
  TServiceStatus
} from 'redux/reducers/client/allState/types/stateTypes'
import { IShopCategories } from 'redux/reducers/commerce/types/stateTypes'
import { lenderActions } from 'redux/reducers/lender/allState/lenderActions'
import {
  actions,
  IAddNewRule,
  IAddScoreCardRiskSegment,
  IClearAllCommissions,
  IClearAllFines,
  IClearNewPolicy,
  IClearProductFields,
  IDeleteDueDateReminder,
  IDeleteRule,
  IDeleteScoreCardRiskSegment,
  IEditDueDateReminder,
  IEditLoyaltyProgram,
  IEditNotification,
  IEditPromoCode,
  IEditRule,
  IEditShop,
  IEditShopUser,
  IGetAllCommissions,
  IGetAllFines,
  IGetAllPolicies,
  IGetBranches,
  IGetCategories,
  IGetCommerces,
  IGetCommissionsList,
  IGetPermissions,
  IGetQuestions,
  IGetQuestionTemplates,
  IGetRegions,
  IGetRoles,
  IGetScoringModels,
  IGetUserPermissions,
  IGetUsers,
  INewDueDateReminder,
  INewLoyaltyProgram,
  INewPromoCode,
  INewShop,
  INewShopUser,
  ISetAllowedRules,
  ISetAllUsers,
  ISetAppActiveTab,
  ISetApplicataionsToastMessage,
  ISetApplicationBankId,
  ISetApplicationDocumentAdditionalContract,
  ISetApplicationDocumentContract,
  ISetApplicationDocumentGuarantorContract,
  ISetApplicationDocumentInvoice,
  ISetApplicationGuarantorBankId, ISetApplicationGuarantorBankIdOpen,
  ISetApplicationGuarantorQuestionnaire, ISetApplicationGuarantorQuestionnaireOpen,
  ISetApplicationGuarantors,
  ISetApplicationGuarantorSummaryInfoOpen,
  ISetApplicationGuarantorUBKIInfo, ISetApplicationGuarantorUBKIInfoOpen,
  ISetApplicationLoanHistory,
  ISetApplicationLoanHistoryServiceStatus,
  ISetApplicationPayments,
  ISetApplicationPaymentsRSE,
  ISetApplicationPlatonPayments,
  ISetApplicationQuestionaryData,
  ISetApplicationQuestionaryDocuments,
  ISetApplications,
  ISetApplicationUbkiCreditScore,
  ISetApplicationWorkflows,
  ISetApplicationYouControl,
  ISetAppsFilterRules,
  ISetAppsSearchQuery,
  ISetCollateralTypes,
  ISetCommerceCategories,
  ISetCreateScoreCardRiskSegmentModalStatus,
  ISetCreditPolicyCollateralStatus,
  ISetCreditPolicyCurrentCollateral,
  ISetCreditPolicyCurrentStep,
  ISetCurrentApplication,
  ISetCurrentPolicy,
  ISetCurrentRiskSegmentErrors,
  ISetCurrentScoreCardRiskSegment,
  ISetDueDateReminders,
  ISetFetchingStatus,
  ISetImportExportHistory,
  ISetImportExportTotalCount,
  ISetInitFetchingStatus,
  ISetIsLoadingImport,
  ISetLenderCategories,
  ISetLenderCurrentVoting,
  ISetLenderPolicies,
  ISetLenderTabName,
  ISetLenderToastMessage,
  ISetLenderUsers,
  ISetLenderVotingId,
  ISetLenderVotingLoadStatus,
  ISetLenderVotingModalVisibleStatus,
  ISetLoanRoutingCreditCommittees,
  ISetLoanRoutingCurrentCreditCommittee,
  ISetLoyaltyPrograms,
  ISetNewPolicyId,
  ISetNotifications,
  ISetPlatonPayments,
  ISetPolicyCategories,
  ISetPolicyShops,
  ISetPromoCodes,
  ISetRiskSegmentSuccessMessage,
  ISetRulesList,
  ISetScorecardAlertModalStatus,
  IsetScoreCardRiskSegmentModalStatus,
  IsetScorecardRiskSegments,
  ISetSettingsContractReplacementList,
  ISetSettingsContractTemplateList,
  ISetSettingsContractTemplateModalStatus,
  ISetSettingsContractTemplatesStatus,
  ISetSettingsCurrentContractTemplate,
  ISetSettingsCurrentDiscount,
  ISetSettingsDiscountList,
  ISetSettingsDiscountModalStatus,
  ISetSettingsDiscountStatus,
  ISetShops,
  ISetShopUsers,
  ISetUnderwritingCurrentDepartment,
  ISetUnderwritingCurrentVerifier,
  ISetUnderwritingDepartments,
  ISetUnderwritingLoadingStatuses,
  ISetUnderwritingModalStatuses,
  ISetUserLogs,
  ISetUserRating,
  IUpdateScoreCardRiskSegment
} from 'redux/reducers/lender/allState/types/actions'
import {
  IAllowedRule,
  IAppDocuments,
  IApplicationGuarantorsList,
  IApplicationGuarantorsListForm,
  IApplicationGuarantorUbki,
  IApplicationUbkiCreditScore,
  IApplicationYouControlData,
  IAppPlatonPayments,
  IAppQuestionaryData,
  IAppQuestionaryDocuments,
  IBankIdAuthRequestsResponseType,
  IBranch,
  ICategory,
  ICollateralType,
  IContractReplacement,
  IContractTemplate,
  ICreditCommittee,
  ICreditPolicyCollateralResponse,
  ICreditProduct,
  IDepartment,
  IDiscount,
  IDueDateReminder,
  IFilterRules,
  IImportResponse,
  ILenderApplication,
  ILoyaltyProgram,
  INotification,
  IPermission,
  IPlatonPayments,
  IPlatonPaymentsData,
  IPromoCode,
  IRiskSegment,
  IRole,
  IRule,
  IScoringSettings,
  IShop,
  IShopUser,
  IUser,
  IUserLogs,
  IUserPermission,
  IVerifier,
  IVoting,
  lenderState,
  TCollateralStatuses,
  TContractTemplatesStatus,
  TDiscountStatus,
  TLenderTabName,
  TLoanRoutingModalStatuses
} from 'redux/reducers/lender/allState/types/state'
import { IToastMessage } from 'types/common/types'

const initialState: lenderState = {
  questionForm: {
    questions: [],
    templates: []
  },
  creditPolicy: {
    creditPolicies: [],
    currentPolicy: null,
    commerces: [],
    categories: [],
    commissionList: [],
    newPolicy: {
      id: null
    },
    currentStep: 0,
    allCommissions: null,
    newCommission: null,
    allFines: null,
    newFine: null,
    regions: [],
    users: [],
    branches: [],
    permissions: [],
    roles: [],
    collateralTypes: [],
    collateral: {
      current: null,
      status: 'init'
    }
  },
  userPermissions: [],
  applications: {
    searchQuery: null,
    isInitFetching: false,
    isFetching: false,
    tabName: null,
    totalCount: null,
    toastMessage: null,
    applicationsList: [],
    currentApplication: null,
    loanHistory: {
      list: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      status: 'load'
    },
    userRating: null,
    questionaryData: null,
    questionaryDocuments: null,
    documentContract: null,
    documentAdditionalContract: null,
    documentInvoice: null,
    documentGuarantorContract: null,
    platonPayments: [],
    users: [],
    categories: [],
    policies: [],
    filterRules: null,
    appTabs: {
      activeTab: 0
    },
    payments: [],
    paymentsRSE: [],
    workflows: [],
    bankId: null,
    guarantors: {
      list: [],
      questionnaire: {
        open: false,
        info: null
      },
      ubkiInfo: {
        open: false,
        info: null
      },
      bankId: {
        open: false,
        info: null
      },
      summaryInfo: {
        open: false
      }
    },
    ubkiCreditScore: null,
    youcontrol: null
  },
  voting: {
    isOpen: false,
    isLoad: false,
    id: null,
    current: null
  },
  rulesList: [],
  userLogs: {} as IUserLogs,
  allowedRules: [],
  scoringModels: [],
  scoreCard: {
    editRiskModalStatus: false,
    createRiskModalStatus: false,
    alertModalStatus: false,
    riskSegments: [],
    currentRiskSegment: {
      name: '',
      score_from: 0,
      score_to: 0,
      default_probability_from: 0,
      default_probability_to: 0,
      expected_distribution: 0,
      recomendation: '',
      action: 'Approve',
      scoring_model: null,
      multiply_interest: '0.0',
      multiply_amount: '0.0'
    },
    errors: null,
    successMessage: ''
  },
  loanRouting: {
    isloading: {
      departmentsTable: false,
      departmentForm: false,
      verifierForm: false
    },
    isOpen: {
      newDepartment: false,
      newVerifier: false,
      existingVerifier: false,
      creditCommitteForm: false,
      userForm: false
    },
    currentDepartment: null,
    currentCreditCommittee: null,
    currentVerifier: null,
    departmentsList: [],
    creditCommitteesList: []
  },
  settings: {
    importExportTotalCount: 0,
    importExportHistory: [],
    promoCodes: [] as IPromoCode[],
    loyaltyPrograms: [] as ILoyaltyProgram[],
    discount: {
      isModalShow: false,
      current: null,
      status: 'init',
      list: []
    },
    contractTemplates: {
      isModalShow: false,
      current: null,
      status: 'init',
      replacementList: [],
      list: []
    }
  },
  scoringSettings: {
    type: null,
    score_from: 0,
    score_to: 0,
    segments: 0
  },
  notifications: {
    general: [] as INotification[],
    reminders: [] as IDueDateReminder[]
  },
  allUsers: [],
  shops: [],
  shopUsers: [],
  commerceCategories: [],
  platonPayments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  toastMessage: null,
  isLoadingImport: false
}
const lenderReducer = (state = initialState, action: actions): lenderState => {
  switch (action.type) {
    case lenderActions.GET_COMMERCES: {
      const commerces = action.payload.map((el: any) => {
        return {
          name: el.name,
          id: el.id
        }
      })
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          commerces
        }
      }
    }
    case lenderActions.GET_CATEGORIES: {
      const categories = action.payload.map((el: any) => {
        return {
          name: el.name,
          id: el.id
        }
      })
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          categories
        }
      }
    }
    case lenderActions.GET_COMMISSIONS_LIST: {
      const commissionList = action.payload.map((el: any) => ({
        name: el.name,
        id: el.id,
        policyId: el.credit_policy
      }))
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          commissionList
        }
      }
    }
    case lenderActions.CLEAR_PRODUCT_FIELDS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          commerces: [],
          categories: []
        }
      }
    }
    // policies
    case lenderActions.SET_POLICY_SHOPS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          currentPolicy: state.creditPolicy.currentPolicy
            ? {
                ...state.creditPolicy.currentPolicy,
                shops: action.payload.data
              }
            : null
        }
      }
    }
    case lenderActions.SET_POLICY_CATEGORIES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          currentPolicy: state.creditPolicy.currentPolicy
            ? {
                ...state.creditPolicy.currentPolicy,
                categories: action.payload.data
              }
            : state.creditPolicy.currentPolicy
        }
      }
    }
    case lenderActions.SET_NEW_POLICY_ID: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          newPolicy: {
            ...state.creditPolicy.newPolicy,
            id: action.payload.id
          }
        }
      }
    }
    case lenderActions.SET_COLLATERAL_TYPES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          collateralTypes: action.payload
        }
      }
    }
    case lenderActions.GET_ALL_POLICIES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          creditPolicies: action.payload
        }
      }
    }
    case lenderActions.SET_CURRENT_POLICY: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          currentPolicy: action.payload
        }
      }
    }
    case lenderActions.SET_POLICY_COLLATERAL_STATUS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          collateral: {
            ...state.creditPolicy.collateral,
            status: action.payload
          }
        }
      }
    }
    case lenderActions.SET_POLICY_CURRENT_COLLATERAL: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          collateral: {
            ...state.creditPolicy.collateral,
            current: action.payload
          }
        }
      }
    }
    case lenderActions.SET_POLICY_CURRENT_STEP: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          currentStep: action.payload
        }
      }
    }
    case lenderActions.CLEAR_NEW_POLICY: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          newPolicy: {
            id: null
          }
        }
      }
    }
    // commissions
    case lenderActions.GET_ALL_COMMISSIONS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          allCommissions: action.payload
        }
      }
    }
    case lenderActions.CLEAR_ALL_COMMISSIONS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          allCommissions: null
        }
      }
    }
    case lenderActions.CLEAR_ALL_FINES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          allFines: null
        }
      }
    }
    // fines
    case lenderActions.GET_ALL_FINES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          allFines: action.payload
        }
      }
    }
    // regions
    case lenderActions.GET_REGIONS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          regions: action.payload
        }
      }
    }
    // users
    case lenderActions.GET_USERS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          users: action.payload
        }
      }
    }
    // branches
    case lenderActions.GET_BRANCHES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          branches: action.payload
        }
      }
    }
    // permissions
    case lenderActions.GET_PERMISSIONS: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          permissions: action.payload
        }
      }
    }
    // roles
    case lenderActions.GET_ROLES: {
      return {
        ...state,
        creditPolicy: {
          ...state.creditPolicy,
          roles: action.payload
        }
      }
    }
    // user permissions
    case lenderActions.GET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: [...state.userPermissions, { ...action.payload }]
      }
    }
    // questions
    case lenderActions.GET_QUESTIONS: {
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          questions: action.payload
        }
      }
    }
    case lenderActions.GET_QUESTION_TEMPLATES: {
      return {
        ...state,
        questionForm: {
          ...state.questionForm,
          templates: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_SCORING_SETTINGS: {
      return {
        ...state,
        scoringSettings: action.payload
      }
    }

    case lenderActions.GET_SCORING_MODELS: {
      return {
        ...state,
        scoringModels: [...action.payload]
      }
    }

    case lenderActions.SET_SCORECARD_RISK_SEGMENTS: {
      const riskSegments = action.payload.map(risk => {
        return {
          ...risk,
          default_probability_from: +(risk.default_probability_from * 100).toFixed(2),
          default_probability_to: +(risk.default_probability_to * 100).toFixed(2),
          expected_distribution: +(risk.expected_distribution * 100).toFixed(2)
        }
      })
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          riskSegments
        }
      }
    }
    case lenderActions.SET_CURRENT_SCORECARD_RISK_SEGMENT: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          currentRiskSegment: action.payload
        }
      }
    }
    case lenderActions.SET_CURRENT_RISK_SEGMENT_ERRORS: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          errors: action.payload
        }
      }
    }
    case lenderActions.SET_RISK_SEGMENT_SUCCESS_MESSAGE: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          successMessage: action.payload
        }
      }
    }
    case lenderActions.UPDATE_SCORECARD_RISK_SEGMENT: {
      const risk = action.payload
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          riskSegments: [
            ...state.scoreCard.riskSegments,
            {
              ...risk,
              default_probability_from: +(risk.default_probability_from * 100).toFixed(2),
              default_probability_to: +(risk.default_probability_to * 100).toFixed(2),
              expected_distribution: +(risk.expected_distribution * 100).toFixed(2)
            }
          ]
        }
      }
    }
    case lenderActions.DELETE_SCORECARD_RISK_SEGMENT: {
      const updatedRisks = state.scoreCard.riskSegments.filter(risk => risk.id !== action.payload)
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          riskSegments: updatedRisks
        }
      }
    }
    case lenderActions.ADD_SCORECARD_RISK_SEGMENT: {
      const risk = action.payload
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          riskSegments: [
            ...state.scoreCard.riskSegments,
            {
              ...risk,
              default_probability_from: +(risk.default_probability_from * 100).toFixed(2),
              default_probability_to: +(risk.default_probability_to * 100).toFixed(2),
              expected_distribution: +(risk.expected_distribution * 100).toFixed(2)
            }
          ]
        }
      }
    }
    case lenderActions.SET_SCORECARD_RISK_SEGMENT_MODAL_STATUS: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          editRiskModalStatus: action.payload
        }
      }
    }
    case lenderActions.SET_CREATE_SCORECARD_RISK_SEGMENT_MODAL_STATUS: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          createRiskModalStatus: action.payload
        }
      }
    }
    case lenderActions.SET_SCORECARD_ALERT_MODAL_STATUS: {
      return {
        ...state,
        scoreCard: {
          ...state.scoreCard,
          alertModalStatus: action.payload
        }
      }
    }
    // applications
    case lenderActions.SET_INIT_FETCHING_STATUS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          isInitFetching: action.payload
        }
      }
    }
    case lenderActions.SET_FETCHING_STATUS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          isFetching: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_TABNAME: {
      return {
        ...state,
        applications: {
          ...state.applications,
          tabName: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_APPLICATIONS: {
      const { count, results } = action.payload

      return {
        ...state,
        applications: {
          ...state.applications,
          totalCount: count,
          applicationsList: results
        }
      }
    }
    case lenderActions.SET_CURRENT_APPLICATION: {
      return {
        ...state,
        applications: {
          ...state.applications,
          currentApplication: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATIONS_ERROR_MESSAGE: {
      return {
        ...state,
        applications: {
          ...state.applications,
          toastMessage: action.payload
        }
      }
    }
    case lenderActions.SET_APP_ACTIVE_TAB: {
      return {
        ...state,
        applications: {
          ...state.applications,
          appTabs: {
            ...state.applications.appTabs,
            activeTab: action.payload
          }
        }
      }
    }
    case lenderActions.SET_APPLICATION_PAYMENTS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          payments: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_PAYMENTS_RSE: {
      return {
        ...state,
        applications: {
          ...state.applications,
          paymentsRSE: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_BANK_ID: {
      return {
        ...state,
        applications: {
          ...state.applications,
          bankId: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_LOAN_HISTORY: {
      return {
        ...state,
        applications: {
          ...state.applications,
          loanHistory: {
            ...state.applications.loanHistory,
            list: action.payload
          }
        }
      }
    }
    case lenderActions.SET_APPLICATION_LOAN_HISTORY_SERVICE_STATUS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          loanHistory: {
            ...state.applications.loanHistory,
            status: action.payload
          }
        }
      }
    }
    case lenderActions.SET_APPLICATION_WORKFLOWS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          workflows: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_QUESTIONARY_DATA: {
      return {
        ...state,
        applications: {
          ...state.applications,
          questionaryData: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_QUESTIONARY_DOCUMENTS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          questionaryDocuments: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_DOCUMENT_CONTRACT: {
      return {
        ...state,
        applications: {
          ...state.applications,
          documentContract: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_DOCUMENT_ADDITIONAL_CONTRACT: {
      return {
        ...state,
        applications: {
          ...state.applications,
          documentAdditionalContract: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_DOCUMENT_INVOICE: {
      return {
        ...state,
        applications: {
          ...state.applications,
          documentInvoice: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_DOCUMENT_GUARANTOR_CONTRACT: {
      return {
        ...state,
        applications: {
          ...state.applications,
          documentGuarantorContract: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_PLATON_PAYMENTS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          platonPayments: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_USERS: {
      const underwritingStatusid = 2
      const usersWithPermissions = action.payload.filter(({ permissions_scope }) =>
        permissions_scope.some(({ id }) => id === underwritingStatusid)
      )
      return {
        ...state,
        applications: {
          ...state.applications,
          users: usersWithPermissions
        }
      }
    }
    case lenderActions.SET_LENDER_CATEGORIES: {
      return {
        ...state,
        applications: {
          ...state.applications,
          categories: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_POLICIES: {
      return {
        ...state,
        applications: {
          ...state.applications,
          policies: action.payload
        }
      }
    }
    case lenderActions.SET_USER_RAITING: {
      return {
        ...state,
        applications: {
          ...state.applications,
          userRating: action.payload
        }
      }
    }
    case lenderActions.SET_APPS_FILTER_RULES: {
      return {
        ...state,
        applications: {
          ...state.applications,
          filterRules: action.payload
        }
      }
    }
    case lenderActions.SET_APPS_SEARCH_QUERY: {
      return {
        ...state,
        applications: {
          ...state.applications,
          searchQuery: action.payload
        }
      }
    }
    case lenderActions.SET_APPLICATION_GUARANTORS_LIST: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            list: action.payload
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_BANK_ID: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            bankId: {
              ...state.applications.guarantors.bankId,
              info: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_BANK_ID_OPEN: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            bankId: {
              ...state.applications.guarantors.bankId,
              open: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_UBKI_INFO: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            ubkiInfo: {
              ...state.applications.guarantors.ubkiInfo,
              info: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_UBKI_INFO_OPEN: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            ubkiInfo: {
              ...state.applications.guarantors.ubkiInfo,
              open: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_QUESTIONNAIRE: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            questionnaire: {
              ...state.applications.guarantors.questionnaire,
              info: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_QUESTIONNAIRE_OPEN: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            questionnaire: {
              ...state.applications.guarantors.questionnaire,
              open: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_GUARANTOR_SUMMARY_INFO_OPEN: {
      return {
        ...state,
        applications: {
          ...state.applications,
          guarantors: {
            ...state.applications.guarantors,
            summaryInfo: {
              ...state.applications.guarantors.summaryInfo,
              open: action.payload
            }
          }
        }
      }
    }

    case lenderActions.SET_APPLICATION_UBKI_CREDIT_SCORE: {
      return {
        ...state,
        applications: {
          ...state.applications,
          ubkiCreditScore: action.payload
        }
      }
    }

    case lenderActions.SET_APPLICATION_YOUCONTROL_DATA: {
      return {
        ...state,
        applications: {
          ...state.applications,
          youcontrol: action.payload
        }
      }
    }
    // rules
    case lenderActions.SET_RULES_LIST: {
      return {
        ...state,
        rulesList: action.payload
      }
    }
    case lenderActions.SET_ALLOWED_RULES: {
      return {
        ...state,
        allowedRules: action.payload
      }
    }
    case lenderActions.ADD_NEW_RULE: {
      return {
        ...state,
        rulesList: [...state.rulesList, action.payload]
      }
    }
    case lenderActions.EDIT_RULE: {
      return {
        ...state,
        rulesList: state.rulesList.map(rule =>
          rule.id === action.payload.id
            ? {
                ...rule,
                condition: action.payload.condition,
                value: action.payload.value,
                action: action.payload.action,
                credit_policy: action.payload.credit_policy,
                rule: {
                  ...rule.rule,
                  is_hard_rule: action.payload.is_hard_rule
                }
              }
            : rule
        )
      }
    }
    case lenderActions.DELETE_RULE: {
      return {
        ...state,
        rulesList: state.rulesList.filter((item, index) => index! === action.payload)
      }
    }
    case lenderActions.SET_USER_LOGS: {
      return {
        ...state,
        userLogs: action.payload
      }
    }
    case lenderActions.SET_ALL_USERS: {
      return {
        ...state,
        allUsers: action.payload
      }
    }
    // settings
    case lenderActions.SET_IMPORT_EXPORT_HISTORY: {
      return {
        ...state,
        settings: {
          ...state.settings,
          importExportHistory: action.payload
        }
      }
    }
    case lenderActions.SET_IMPORT_EXPORT_TOTAL_COUNT: {
      return {
        ...state,
        settings: {
          ...state.settings,
          importExportTotalCount: action.payload
        }
      }
    }
    case lenderActions.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          general: action.payload
        }
      }
    }
    case lenderActions.EDIT_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          general: state.notifications.general.map(notification =>
            notification.id === action.payload.id
              ? {
                  ...notification,
                  title: action.payload.title,
                  is_email_enabled: action.payload.is_email_enabled,
                  is_sms_enabled: action.payload.is_sms_enabled,
                  email_template: action.payload.email_template,
                  sms_template: action.payload.sms_template,
                  description: action.payload.description
                }
              : notification
          )
        }
      }
    }
    case lenderActions.SET_DUE_DATE_REMINDERS: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          reminders: action.payload
        }
      }
    }
    case lenderActions.NEW_DUE_DATE_REMINDER: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          reminders: [...state.notifications.reminders, action.payload]
        }
      }
    }
    case lenderActions.EDIT_DUE_DATE_REMINDER: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          reminders: state.notifications.reminders.map(reminder =>
            reminder.id === action.payload.id
              ? {
                  ...reminder,
                  days: action.payload.days
                }
              : reminder
          )
        }
      }
    }
    case lenderActions.DELETE_DUE_DATE_REMINDER: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          reminders: state.notifications.reminders.filter(
            (item, index) => item.id !== action.payload
          )
        }
      }
    }
    case lenderActions.SET_PROMO_CODES: {
      return {
        ...state,
        settings: {
          ...state.settings,
          promoCodes: action.payload
        }
      }
    }
    case lenderActions.NEW_PROMO_CODE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          promoCodes: [...state.settings.promoCodes, action.payload]
        }
      }
    }
    case lenderActions.EDIT_PROMO_CODE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          promoCodes: state.settings.promoCodes.map(promoCode =>
            promoCode.id === action.payload.id
              ? {
                  ...promoCode,
                  discount: action.payload.discount
                }
              : promoCode
          )
        }
      }
    }
    case lenderActions.DELETE_PROMO_CODE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          promoCodes: state.settings.promoCodes.filter((item, index) => index! === action.payload)
        }
      }
    }
    case lenderActions.SET_LOYALTY_PROGRAMS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loyaltyPrograms: action.payload
        }
      }
    }
    case lenderActions.NEW_LOYALTY_PROGRAM: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loyaltyPrograms: [...state.settings.loyaltyPrograms, action.payload]
        }
      }
    }
    case lenderActions.EDIT_LOYALTY_PROGRAM: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loyaltyPrograms: state.settings.loyaltyPrograms.map(loyaltyProgram =>
            loyaltyProgram.id === action.payload.id
              ? {
                  ...loyaltyProgram,
                  loans_count_from: action.payload.loans_count_from,
                  loans_count_to: action.payload.loans_count_to,
                  discount: action.payload.discount,
                  credit_policy: action.payload.credit_policy
                }
              : loyaltyProgram
          )
        }
      }
    }
    case lenderActions.DELETE_LOYALTY_PROGRAM: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loyaltyPrograms: state.settings.loyaltyPrograms.filter(
            (item, index) => index! === action.payload
          )
        }
      }
    }
    case lenderActions.SET_SHOPS: {
      return {
        ...state,
        shops: action.payload
      }
    }
    case lenderActions.NEW_SHOP: {
      return {
        ...state,
        shops: [...state.shops, action.payload]
      }
    }
    case lenderActions.EDIT_SHOP: {
      return {
        ...state,
        shops: state.shops.map(shop =>
          shop.id === action.payload.id
            ? {
                ...shop,
                url: action.payload.url,
                name: action.payload.name,
                iban: action.payload.iban,
                registry_identifier: action.payload.registry_identifier,
                address: action.payload.address,
                bank_name: action.payload.bank_name,
                bank_code: action.payload.bank_code,
                years_in_bussiness: action.payload.years_in_bussiness,
                number_of_employees: action.payload.number_of_employees,
                description: action.payload.description,
                billing_address: action.payload.billing_address
              }
            : shop
        )
      }
    }
    case lenderActions.DELETE_SHOP: {
      return {
        ...state,
        shops: state.shops.filter((item, index) => index! === action.payload)
      }
    }
    case lenderActions.SET_SHOP_USERS: {
      return {
        ...state,
        shopUsers: action.payload
      }
    }
    case lenderActions.NEW_SHOP_USER: {
      return {
        ...state,
        shopUsers: [...state.shopUsers, action.payload]
      }
    }
    case lenderActions.EDIT_SHOP_USER: {
      return {
        ...state,
        shopUsers: state.shopUsers.map(user =>
          user.id === action.payload.id
            ? {
                ...user,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                email: action.payload.email
              }
            : user
        )
      }
    }
    case lenderActions.DELETE_SHOP_USER: {
      return {
        ...state,
        shopUsers: state.shopUsers.filter((item, index) => index! === action.payload)
      }
    }
    case lenderActions.SET_LENDER_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload
      }
    }
    case lenderActions.SET_UNDERWRITING_DEPARTMENTS: {
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          departmentsList: action.payload
        }
      }
    }
    case lenderActions.SET_LOAN_ROUTING_CREDIT_COMMITTEES: {
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          creditCommitteesList: action.payload
        }
      }
    }
    case lenderActions.SET_UNDERWRITING_LOADING_STATUSES: {
      const { type, status } = action.payload
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          isloading: {
            ...state.loanRouting.isloading,
            [type]: status
          }
        }
      }
    }
    case lenderActions.SET_UNDERWRITING_MODAL_STATUSES: {
      const { type, status } = action.payload
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          isOpen: {
            ...state.loanRouting.isOpen,
            [type]: status
          }
        }
      }
    }
    case lenderActions.SET_UNDERWRITING_CURRENT_DEPARTMENT: {
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          currentDepartment: action.payload
        }
      }
    }
    case lenderActions.SET_LOAN_ROUTING_CURRENT_CREDIT_COMMITTEE: {
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          currentCreditCommittee: action.payload
        }
      }
    }
    case lenderActions.SET_UNDERWRITING_CURRENT_VERIFIER: {
      return {
        ...state,
        loanRouting: {
          ...state.loanRouting,
          currentVerifier: action.payload
        }
      }
    }
    case lenderActions.SET_VOTING_MODAL_VISIBLE_STATUS: {
      return {
        ...state,
        voting: {
          ...state.voting,
          isOpen: action.payload
        }
      }
    }
    case lenderActions.SET_VOTING_MODAL_LOAD_STATUS: {
      return {
        ...state,
        voting: {
          ...state.voting,
          isLoad: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_VOTING_ID: {
      return {
        ...state,
        voting: {
          ...state.voting,
          id: action.payload
        }
      }
    }
    case lenderActions.SET_LENDER_CURRENT_VOTING: {
      return {
        ...state,
        voting: {
          ...state.voting,
          current: action.payload
        }
      }
    }
    case lenderActions.SET_SETTINGS_CONTRACT_REPLACEMENT_LIST: {
      return {
        ...state,
        settings: {
          ...state.settings,
          contractTemplates: {
            ...state.settings.contractTemplates,
            replacementList: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_CONTRACT_TEMPLATE_LIST: {
      return {
        ...state,
        settings: {
          ...state.settings,
          contractTemplates: {
            ...state.settings.contractTemplates,
            list: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_CONTRACT_TEMPLATES_STATUS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          contractTemplates: {
            ...state.settings.contractTemplates,
            status: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_CONTRACT_TEMPLATE_MODAL_STATUS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          contractTemplates: {
            ...state.settings.contractTemplates,
            isModalShow: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_CURRENT_CONTRACT_TEMPLATE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          contractTemplates: {
            ...state.settings.contractTemplates,
            current: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_DISCOUNT_LIST: {
      return {
        ...state,
        settings: {
          ...state.settings,
          discount: {
            ...state.settings.discount,
            list: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_DISCOUNT_STATUS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          discount: {
            ...state.settings.discount,
            status: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_CURRENT_DISCOUNT: {
      return {
        ...state,
        settings: {
          ...state.settings,
          discount: {
            ...state.settings.discount,
            current: action.payload
          }
        }
      }
    }
    case lenderActions.SET_SETTINGS_DISCOUNT_MODAL_STATUS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          discount: {
            ...state.settings.discount,
            isModalShow: action.payload
          }
        }
      }
    }
    case lenderActions.SET_COMMERCE_CATEGORIES: {
      return {
        ...state,
        commerceCategories: action.payload
      }
    }
    // platon payments
    case lenderActions.SET_PLATON_PAYMENTS: {
      return {
        ...state,
        platonPayments: action.payload
      }
    }
    // is loading import
    case lenderActions.SET_IS_LOADING_IMPORT: {
      return {
        ...state,
        isLoadingImport: action.payload
      }
    }
    default:
      return state
  }
}

// action creators
export const clearNewPolicy = (): IClearNewPolicy => ({ type: lenderActions.CLEAR_NEW_POLICY })
export const clearAllCommissions = (): IClearAllCommissions => ({
  type: lenderActions.CLEAR_ALL_COMMISSIONS
})
export const clearAllFines = (): IClearAllFines => ({ type: lenderActions.CLEAR_ALL_FINES })
export const getAllPolicies = (data: ICreditProduct[]): IGetAllPolicies => ({
  type: lenderActions.GET_ALL_POLICIES,
  payload: data
})
export const setCurrentPolicy = (policy: ICreditProduct | null): ISetCurrentPolicy => ({
  type: lenderActions.SET_CURRENT_POLICY,
  payload: policy
})

export const setCreditPolicyCollateralStatus = (
  status: TCollateralStatuses
): ISetCreditPolicyCollateralStatus => ({
  type: lenderActions.SET_POLICY_COLLATERAL_STATUS,
  payload: status
})

export const setCreditPolicyCurrentCollateral = (
  collateral: ICreditPolicyCollateralResponse | null
): ISetCreditPolicyCurrentCollateral => ({
  type: lenderActions.SET_POLICY_CURRENT_COLLATERAL,
  payload: collateral
})

export const setCreditPolicyCurrentStep = (step: number): ISetCreditPolicyCurrentStep => ({
  type: lenderActions.SET_POLICY_CURRENT_STEP,
  payload: step
})

export const getRegions = (data: string[]): IGetRegions => ({
  type: lenderActions.GET_REGIONS,
  payload: data
})

export const getUsers = (data: IUser[]): IGetUsers => ({
  type: lenderActions.GET_USERS,
  payload: data
})

export const getBranches = (data: IBranch[]): IGetBranches => ({
  type: lenderActions.GET_BRANCHES,
  payload: data
})

export const getPermissions = (data: IPermission[]): IGetPermissions => ({
  type: lenderActions.GET_PERMISSIONS,
  payload: data
})

export const getRoles = (data: IRole[]): IGetRoles => ({
  type: lenderActions.GET_ROLES,
  payload: data
})
export const getUserPermissions = (data: IUserPermission): IGetUserPermissions => ({
  type: lenderActions.GET_USER_PERMISSIONS,
  payload: data
})

export const setPolicyShops = (data: any[], policyId: number): ISetPolicyShops => ({
  type: lenderActions.SET_POLICY_SHOPS,
  payload: {
    data,
    policyId
  }
})

export const setPolicyCategories = (data: any[], policyId: number): ISetPolicyCategories => ({
  type: lenderActions.SET_POLICY_CATEGORIES,
  payload: {
    data,
    policyId
  }
})

export const setNewPolicyId = (id: number): ISetNewPolicyId => ({
  type: lenderActions.SET_NEW_POLICY_ID,
  payload: { id }
})

export const setCollateralTypes = (types: ICollateralType[]): ISetCollateralTypes => ({
  type: lenderActions.SET_COLLATERAL_TYPES,
  payload: types
})

// requests for select fields
export const getCommerces = (data: any[]): IGetCommerces => ({
  type: lenderActions.GET_COMMERCES,
  payload: data
})
export const getCategories = (data: any[]): IGetCategories => ({
  type: lenderActions.GET_CATEGORIES,
  payload: data
})
export const getCommissionList = (data: any[]): IGetCommissionsList => ({
  type: lenderActions.GET_COMMISSIONS_LIST,
  payload: data
})

export const clearProductFields = (): IClearProductFields => ({
  type: lenderActions.CLEAR_PRODUCT_FIELDS
})

// commission
export const getAllCommissions = (data: any[]): IGetAllCommissions => ({
  type: lenderActions.GET_ALL_COMMISSIONS,
  payload: data
})

// fines
export const getAllFines = (data: any[]): IGetAllFines => ({
  type: lenderActions.GET_ALL_FINES,
  payload: data
})

// questions
export const getQuestions = (data: any[]): IGetQuestions => ({
  type: lenderActions.GET_QUESTIONS,
  payload: data
})
export const getQuestionTemplates = (data: any[]): IGetQuestionTemplates => ({
  type: lenderActions.GET_QUESTION_TEMPLATES,
  payload: data
})

// scoring settings
export const setScoringSettings = (settings: IScoringSettings) => ({
  type: lenderActions.SET_LENDER_SCORING_SETTINGS,
  payload: settings
})

// scoring models
export const getScoringModels = (data: any[]): IGetScoringModels => ({
  type: lenderActions.GET_SCORING_MODELS,
  payload: data
})

export const setScorecardRiskSegments = (
  riskSegments: IRiskSegment[]
): IsetScorecardRiskSegments => ({
  type: lenderActions.SET_SCORECARD_RISK_SEGMENTS,
  payload: riskSegments
})

export const setCurrentScoreCardRiskSegment = (
  riskSegment: IRiskSegment
): ISetCurrentScoreCardRiskSegment => ({
  type: lenderActions.SET_CURRENT_SCORECARD_RISK_SEGMENT,
  payload: riskSegment
})
export const setCurrentRiskSegmentErrors = (errors: any): ISetCurrentRiskSegmentErrors => ({
  type: lenderActions.SET_CURRENT_RISK_SEGMENT_ERRORS,
  payload: errors
})
export const setRiskSegmentSuccessMessage = (message: string): ISetRiskSegmentSuccessMessage => ({
  type: lenderActions.SET_RISK_SEGMENT_SUCCESS_MESSAGE,
  payload: message
})
export const updateScoreCardRiskSegment = (
  riskSegment: IRiskSegment
): IUpdateScoreCardRiskSegment => ({
  type: lenderActions.UPDATE_SCORECARD_RISK_SEGMENT,
  payload: riskSegment
})
export const deleteScoreCardRiskSegment = (
  id: number | undefined
): IDeleteScoreCardRiskSegment => ({
  type: lenderActions.DELETE_SCORECARD_RISK_SEGMENT,
  payload: id
})
export const addScoreCardRiskSegment = (riskSegment: IRiskSegment): IAddScoreCardRiskSegment => ({
  type: lenderActions.ADD_SCORECARD_RISK_SEGMENT,
  payload: riskSegment
})
export const setEditScoreCardRiskSegmentModalStatus = (
  status: boolean
): IsetScoreCardRiskSegmentModalStatus => ({
  type: lenderActions.SET_SCORECARD_RISK_SEGMENT_MODAL_STATUS,
  payload: status
})
export const setCreateScoreCardRiskSegmentModalStatus = (
  status: boolean
): ISetCreateScoreCardRiskSegmentModalStatus => ({
  type: lenderActions.SET_CREATE_SCORECARD_RISK_SEGMENT_MODAL_STATUS,
  payload: status
})
export const setScorecardAlertModalStatus = (status: boolean): ISetScorecardAlertModalStatus => ({
  type: lenderActions.SET_SCORECARD_ALERT_MODAL_STATUS,
  payload: status
})

// applications
export const setLenderTabName = (tabName: TLenderTabName): ISetLenderTabName => ({
  type: lenderActions.SET_LENDER_TABNAME,
  payload: tabName
})

export const setInitFetchingStatus = (data: boolean): ISetInitFetchingStatus => ({
  type: lenderActions.SET_INIT_FETCHING_STATUS,
  payload: data
})
export const setFetchingStatus = (data: boolean): ISetFetchingStatus => ({
  type: lenderActions.SET_FETCHING_STATUS,
  payload: data
})
export const setLenderApplications = (data: {
  count: number
  results: ILenderApplication[]
}): ISetApplications => ({
  type: lenderActions.SET_LENDER_APPLICATIONS,
  payload: data
})
export const setAppsFilterRules = (rules: IFilterRules | null): ISetAppsFilterRules => ({
  type: lenderActions.SET_APPS_FILTER_RULES,
  payload: rules
})
export const setAppsSearchQuery = (query: string | null): ISetAppsSearchQuery => ({
  type: lenderActions.SET_APPS_SEARCH_QUERY,
  payload: query
})
export const setCurrentApplication = (
  application: ILenderApplication | null
): ISetCurrentApplication => ({
  type: lenderActions.SET_CURRENT_APPLICATION,
  payload: application
})
export const setApplicataionsToastMessage = (
  toastMessage: IToastMessage | null
): ISetApplicataionsToastMessage => ({
  type: lenderActions.SET_APPLICATIONS_ERROR_MESSAGE,
  payload: toastMessage
})
export const setAppActiveTab = (tabNumber: number): ISetAppActiveTab => ({
  type: lenderActions.SET_APP_ACTIVE_TAB,
  payload: tabNumber
})

export const setApplicationPayments = (data: IApplicationPayment[]): ISetApplicationPayments => ({
  type: lenderActions.SET_APPLICATION_PAYMENTS,
  payload: data
})

export const setApplicationPaymentsRSE = (data: IApplicationRepaymentChange[]): ISetApplicationPaymentsRSE => ({
  type: lenderActions.SET_APPLICATION_PAYMENTS_RSE,
  payload: data
})

export const setApplicationBankIdData = (data: IApplicationBankIdData): ISetApplicationBankId => ({
  type: lenderActions.SET_APPLICATION_BANK_ID,
  payload: data
})

export const setApplicationLoanHistory = (
  data: ILoanHistoryApp
): ISetApplicationLoanHistory => ({
  type: lenderActions.SET_APPLICATION_LOAN_HISTORY,
  payload: data
})
export const setApplicationLoanHistoryServiceStatus = (
  status: TServiceStatus
): ISetApplicationLoanHistoryServiceStatus => ({
  type: lenderActions.SET_APPLICATION_LOAN_HISTORY_SERVICE_STATUS,
  payload: status
})
export const setApplicationWorkflows = (
  workflows: IApplicationWorkflow[]
): ISetApplicationWorkflows => ({
  type: lenderActions.SET_APPLICATION_WORKFLOWS,
  payload: workflows
})
export const setUserRating = (rating: number): ISetUserRating => ({
  type: lenderActions.SET_USER_RAITING,
  payload: rating
})
export const setApplicationQuestionaryData = (
  data: IAppQuestionaryData | null
): ISetApplicationQuestionaryData => ({
  type: lenderActions.SET_APPLICATION_QUESTIONARY_DATA,
  payload: data
})

export const setApplicationQuestionaryDocuments = (
  data: IAppQuestionaryDocuments[] | null
): ISetApplicationQuestionaryDocuments => ({
  type: lenderActions.SET_APPLICATION_QUESTIONARY_DOCUMENTS,
  payload: data
})

export const setApplicationDocumentContract = (
  data: IAppDocuments | null
): ISetApplicationDocumentContract => ({
  type: lenderActions.SET_APPLICATION_DOCUMENT_CONTRACT,
  payload: data
})

export const setApplicationDocumentAdditionalContract = (
  data: IAppDocuments | null
): ISetApplicationDocumentAdditionalContract => ({
  type: lenderActions.SET_APPLICATION_DOCUMENT_ADDITIONAL_CONTRACT,
  payload: data
})

export const setApplicationDocumentInvoice = (
  data: IAppDocuments | null
): ISetApplicationDocumentInvoice => ({
  type: lenderActions.SET_APPLICATION_DOCUMENT_INVOICE,
  payload: data
})

export const setApplicationDocumentGuarantorContract = (
  data: IAppDocuments | null
): ISetApplicationDocumentGuarantorContract => ({
  type: lenderActions.SET_APPLICATION_DOCUMENT_GUARANTOR_CONTRACT,
  payload: data
})

export const setApplicationPlatonPayments = (
  data: IAppPlatonPayments[]
): ISetApplicationPlatonPayments => ({
  type: lenderActions.SET_APPLICATION_PLATON_PAYMENTS,
  payload: data
})

export const setLenderUsers = (users: IUser[]): ISetLenderUsers => ({
  type: lenderActions.SET_LENDER_USERS,
  payload: users
})
export const setLenderCategories = (categories: ICategory[]): ISetLenderCategories => ({
  type: lenderActions.SET_LENDER_CATEGORIES,
  payload: categories
})
export const setLenderPolicies = (policies: ICreditProduct[]): ISetLenderPolicies => ({
  type: lenderActions.SET_LENDER_POLICIES,
  payload: policies
})
export const setApplicationYouControl = (
  data: null | IApplicationYouControlData[]
): ISetApplicationYouControl => ({
  type: lenderActions.SET_APPLICATION_YOUCONTROL_DATA,
  payload: data
})

// loan settings
export const setRulesList = (data: IRule[]): ISetRulesList => ({
  type: lenderActions.SET_RULES_LIST,
  payload: data
})

export const setAllowedRules = (data: IAllowedRule[]): ISetAllowedRules => ({
  type: lenderActions.SET_ALLOWED_RULES,
  payload: data
})

export const addNewRule = (data: IRule): IAddNewRule => ({
  type: lenderActions.ADD_NEW_RULE,
  payload: data
})

export const editRule = (data: IRule): IEditRule => ({
  type: lenderActions.EDIT_RULE,
  payload: data
})

export const deleteRule = (id: number): IDeleteRule => ({
  type: lenderActions.DELETE_RULE,
  payload: id
})

export const setUserLogs = (data: IUserLogs): ISetUserLogs => ({
  type: lenderActions.SET_USER_LOGS,
  payload: data
})

export const setAllUsers = (data: IUser[]): ISetAllUsers => ({
  type: lenderActions.SET_ALL_USERS,
  payload: data
})

export const setImportExportHistory = (data: IImportResponse[]): ISetImportExportHistory => ({
  type: lenderActions.SET_IMPORT_EXPORT_HISTORY,
  payload: data
})

export const setImportExportTotalCount = (data: number): ISetImportExportTotalCount => ({
  type: lenderActions.SET_IMPORT_EXPORT_TOTAL_COUNT,
  payload: data
})

export const setNotifications = (data: INotification[]): ISetNotifications => ({
  type: lenderActions.SET_NOTIFICATIONS,
  payload: data
})

export const editNotification = (data: INotification): IEditNotification => ({
  type: lenderActions.EDIT_NOTIFICATION,
  payload: data
})

export const setDueDateReminders = (data: IDueDateReminder[]): ISetDueDateReminders => ({
  type: lenderActions.SET_DUE_DATE_REMINDERS,
  payload: data
})

export const newDueDateReminder = (data: IDueDateReminder): INewDueDateReminder => ({
  type: lenderActions.NEW_DUE_DATE_REMINDER,
  payload: data
})

export const editDueDateReminder = (data: IDueDateReminder): IEditDueDateReminder => ({
  type: lenderActions.EDIT_DUE_DATE_REMINDER,
  payload: data
})

export const deleteDueDateReminder = (id: number): IDeleteDueDateReminder => ({
  type: lenderActions.DELETE_DUE_DATE_REMINDER,
  payload: id
})

export const setPromoCodes = (data: IPromoCode[]): ISetPromoCodes => ({
  type: lenderActions.SET_PROMO_CODES,
  payload: data
})

export const newPromoCode = (data: IPromoCode): INewPromoCode => ({
  type: lenderActions.NEW_PROMO_CODE,
  payload: data
})

export const editPromoCode = (data: IPromoCode): IEditPromoCode => ({
  type: lenderActions.EDIT_PROMO_CODE,
  payload: data
})

export const setLoyaltyPrograms = (data: ILoyaltyProgram[]): ISetLoyaltyPrograms => ({
  type: lenderActions.SET_LOYALTY_PROGRAMS,
  payload: data
})

export const newLoyaltyProgram = (data: ILoyaltyProgram): INewLoyaltyProgram => ({
  type: lenderActions.NEW_LOYALTY_PROGRAM,
  payload: data
})

export const editLoyaltyProgram = (data: ILoyaltyProgram): IEditLoyaltyProgram => ({
  type: lenderActions.EDIT_LOYALTY_PROGRAM,
  payload: data
})

export const setShops = (data: IShop[]): ISetShops => ({
  type: lenderActions.SET_SHOPS,
  payload: data
})

export const newShop = (data: IShop): INewShop => ({
  type: lenderActions.NEW_SHOP,
  payload: data
})

export const editShop = (data: IShop): IEditShop => ({
  type: lenderActions.EDIT_SHOP,
  payload: data
})

export const setShopUsers = (data: IShopUser[]): ISetShopUsers => ({
  type: lenderActions.SET_SHOP_USERS,
  payload: data
})

export const newShopUser = (data: IShopUser): INewShopUser => ({
  type: lenderActions.NEW_SHOP_USER,
  payload: data
})

export const editShopUser = (data: IShopUser): IEditShopUser => ({
  type: lenderActions.EDIT_SHOP_USER,
  payload: data
})

export const setUnderwritingDepartments = (data: IDepartment[]): ISetUnderwritingDepartments => ({
  type: lenderActions.SET_UNDERWRITING_DEPARTMENTS,
  payload: data
})

export const setLoanRoutingCreditCommittees = (
  data: ICreditCommittee[]
): ISetLoanRoutingCreditCommittees => ({
  type: lenderActions.SET_LOAN_ROUTING_CREDIT_COMMITTEES,
  payload: data
})

export const setUnderwritingLoadingStatuses = (data: {
  type: 'departmentsTable' | 'departmentForm' | 'verifierForm'
  status: boolean
}): ISetUnderwritingLoadingStatuses => ({
  type: lenderActions.SET_UNDERWRITING_LOADING_STATUSES,
  payload: data
})

export const setLoanRoutingModalStatuses = (data: {
  type: TLoanRoutingModalStatuses
  status: boolean
}): ISetUnderwritingModalStatuses => ({
  type: lenderActions.SET_UNDERWRITING_MODAL_STATUSES,
  payload: data
})

export const setUnderwritingCurrentDepartment = (
  department: IDepartment | null
): ISetUnderwritingCurrentDepartment => ({
  type: lenderActions.SET_UNDERWRITING_CURRENT_DEPARTMENT,
  payload: department
})

export const setLoanRoutingCurrentCreditCommittee = (
  creditCommittee: ICreditCommittee | null
): ISetLoanRoutingCurrentCreditCommittee => ({
  type: lenderActions.SET_LOAN_ROUTING_CURRENT_CREDIT_COMMITTEE,
  payload: creditCommittee
})

export const setUnderwritingCurrentVerifier = (
  verifierId: IVerifier | null
): ISetUnderwritingCurrentVerifier => ({
  type: lenderActions.SET_UNDERWRITING_CURRENT_VERIFIER,
  payload: verifierId
})

export const setLenderVotingModalVisibleStatus = (
  status: boolean
): ISetLenderVotingModalVisibleStatus => ({
  type: lenderActions.SET_VOTING_MODAL_VISIBLE_STATUS,
  payload: status
})

export const setLenderVotingLoadStatus = (status: boolean): ISetLenderVotingLoadStatus => ({
  type: lenderActions.SET_VOTING_MODAL_LOAD_STATUS,
  payload: status
})

export const setLenderVotingId = (votingId: number | string | null): ISetLenderVotingId => ({
  type: lenderActions.SET_LENDER_VOTING_ID,
  payload: votingId
})
export const setLenderCurrentVoting = (voting: IVoting | null): ISetLenderCurrentVoting => ({
  type: lenderActions.SET_LENDER_CURRENT_VOTING,
  payload: voting
})

export const setSettingsDiscountList = (list: IDiscount[]): ISetSettingsDiscountList => ({
  type: lenderActions.SET_SETTINGS_DISCOUNT_LIST,
  payload: list
})
export const setSettingsDiscountStatus = (status: TDiscountStatus): ISetSettingsDiscountStatus => ({
  type: lenderActions.SET_SETTINGS_DISCOUNT_STATUS,
  payload: status
})
export const setSettingsCurrentDiscount = (
  current: IDiscount | null
): ISetSettingsCurrentDiscount => ({
  type: lenderActions.SET_SETTINGS_CURRENT_DISCOUNT,
  payload: current
})
export const setSettingsDiscountModalStatus = (
  status: boolean
): ISetSettingsDiscountModalStatus => ({
  type: lenderActions.SET_SETTINGS_DISCOUNT_MODAL_STATUS,
  payload: status
})

export const setCommerceCategories = (categories: IShopCategories[]): ISetCommerceCategories => ({
  type: lenderActions.SET_COMMERCE_CATEGORIES,
  payload: categories
})

export const setLenderToastMessage = (message: IToastMessage | null): ISetLenderToastMessage => {
  return {
    type: lenderActions.SET_LENDER_TOAST_MESSAGE,
    payload: message
  }
}

export const setSettingsContractReplacementList = (list: IContractReplacement[]): ISetSettingsContractReplacementList => ({
  type: lenderActions.SET_SETTINGS_CONTRACT_REPLACEMENT_LIST,
  payload: list
})
export const setSettingsContractTemplatesList = (list: IContractTemplate[]): ISetSettingsContractTemplateList => ({
  type: lenderActions.SET_SETTINGS_CONTRACT_TEMPLATE_LIST,
  payload: list
})
export const setSettingsContractTemplatesStatus = (status: TContractTemplatesStatus): ISetSettingsContractTemplatesStatus => ({
  type: lenderActions.SET_SETTINGS_CONTRACT_TEMPLATES_STATUS,
  payload: status
})
export const setSettingsContractTemplateModalStatus = (
  status: boolean
): ISetSettingsContractTemplateModalStatus => ({
  type: lenderActions.SET_SETTINGS_CONTRACT_TEMPLATE_MODAL_STATUS,
  payload: status
})
export const setSettingsCurrentContractTemplate = (
  current: IContractTemplate | null
): ISetSettingsCurrentContractTemplate => ({
  type: lenderActions.SET_SETTINGS_CURRENT_CONTRACT_TEMPLATE,
  payload: current
})

export const setPlatonPayments = (
  platonPayments: IPlatonPaymentsData
): ISetPlatonPayments => ({
  type: lenderActions.SET_PLATON_PAYMENTS,
  payload: platonPayments
})

export const setIsLoadingImport = (
  isLoadingImport: boolean
): ISetIsLoadingImport => ({
  type: lenderActions.SET_IS_LOADING_IMPORT,
  payload: isLoadingImport
})

export const setApplicationGuarantors = (data: IApplicationGuarantorsList[]): ISetApplicationGuarantors => ({
  type: lenderActions.SET_APPLICATION_GUARANTORS_LIST,
  payload: data
})

export const setApplicationGuarantorBankId = (data: IBankIdAuthRequestsResponseType | null): ISetApplicationGuarantorBankId => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_BANK_ID,
  payload: data
})

export const setApplicationGuarantorBankIdOpen = (open: boolean): ISetApplicationGuarantorBankIdOpen => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_BANK_ID_OPEN,
  payload: open
})

export const setApplicationGuarantorUBKIInfo = (data: IApplicationGuarantorUbki | null): ISetApplicationGuarantorUBKIInfo => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_UBKI_INFO,
  payload: data
})

export const setApplicationGuarantorUBKIInfoOpen = (open: boolean): ISetApplicationGuarantorUBKIInfoOpen => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_UBKI_INFO_OPEN,
  payload: open
})

export const setApplicationGuarantorQuestionnaire = (info: IApplicationGuarantorsListForm | null): ISetApplicationGuarantorQuestionnaire => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_QUESTIONNAIRE,
  payload: info
})

export const setApplicationGuarantorQuestionnaireOpen = (open: boolean): ISetApplicationGuarantorQuestionnaireOpen => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_QUESTIONNAIRE_OPEN,
  payload: open
})

export const setApplicationGuarantorSummaryInfoOpen = (open: boolean): ISetApplicationGuarantorSummaryInfoOpen => ({
  type: lenderActions.SET_APPLICATION_GUARANTOR_SUMMARY_INFO_OPEN,
  payload: open
})

export const setApplicationUbkiCreditScore = (ubkiCreditScore: IApplicationUbkiCreditScore | null): ISetApplicationUbkiCreditScore => ({
  type: lenderActions.SET_APPLICATION_UBKI_CREDIT_SCORE,
  payload: ubkiCreditScore
})

export default lenderReducer
