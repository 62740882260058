import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enLocale from 'date-fns/locale/en-US'
import ukLocale from 'date-fns/locale/uk'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { mobileMaxWidth } from 'features/formConstructor/themes'
import { ICustomInput } from 'features/formConstructor/types'
import { getPattern } from 'features/formConstructor/utils/validation'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import { ITemplateBlock } from 'redux/reducers/constructors/formConstructor/types'
import { languageSelector } from 'redux/reducers/translation/translationSelectors'
import { booleanFromString } from 'utils/common/booleanFromString'
import { replaceCyrillic } from 'utils/helpers'

import { CustomCheckboxInput } from '../common/inputs/CustomCheckboxInput'
import { CustomDateInput } from '../common/inputs/CustomDateInput'
import CustomFileInput from '../common/inputs/CustomFileInput'
import { CustomPhoneInput } from '../common/inputs/CustomPhoneInput'
import { CustomSelectInput } from '../common/inputs/CustomSelectInput'
import { CustomTextInput } from '../common/inputs/CustomTextInput'

import { commonDataFieldNames, documentsDataFieldNames } from './helpers'

export interface IConstructorBlockProps {
  block: ITemplateBlock,
  autofillData?: any
  setCountClearInput: any
  bankIdData?: any
}

const getQuestionName = (input: ICustomInput, lang: string) => {
  let questionResult = ''
  switch (lang) {
    case 'ru': {
      questionResult = input.question_ru || input.question
      break
    }
    case 'uk': {
      questionResult = input.question_uk || input.question
      break
    }
    case 'en': {
      questionResult = input.question_en || input.question
      break
    }
    default: {
      questionResult = input.question
      break
    }
  }

  return questionResult
}
// NEED TO ADD DINAMIC TYPE FOR FORM FIELDS
export const ConstructorBlock: React.FC<IConstructorBlockProps> = ({ block, autofillData, setCountClearInput, bankIdData }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const lang = useSelector(languageSelector)

  const { title } = block
  const blockName = replaceCyrillic(title.toLowerCase()
    .replace(/[\s,]/g, '_')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, '_'))

  const { clientApplication } = constructorActions
  // const { mandatoryQuestionsList } = useSelector(clientApplicationDataSelector)
  const currentLanguage = useSelector(languageSelector)

  const { control, setValue, watch } = useFormContext()

  const isMobile = useMediaQuery(mobileMaxWidth)
  const [valuesFromBankId, setValuesFromBankId] = useState<{ [key: string]: any }>({})

  const sortedInputs = useMemo(
    () => [...block.inputs].sort((a, b) => a.styles.order - b.styles.order),
    [block.inputs]
  )

  useEffect(() => {
    for (const key of block.inputs) {
      if (key?.name) {
        dispatch(clientApplication.setClientsMandatoryQuestionsList(key.name))
      }
    }
  }, [block.inputs, clientApplication, dispatch])

  useEffect(() => {
    if (autofillData) {
      for (const keyAutoFill of Object.keys(autofillData)) {
        for (const keyInput of block?.inputs) {
          if (keyInput?.name?.toLowerCase() === keyAutoFill?.toLowerCase()) {
            if (!valuesFromBankId[keyAutoFill]) {
              setValue(`${blockName}.${keyAutoFill}`, autofillData[keyAutoFill])
            }
          }
        }
      }
    }
  }, [autofillData, block.inputs])

  useEffect(() => {
    if (Object.keys(valuesFromBankId).length !== 0) {
      for (const keyAutoFill of Object.keys(valuesFromBankId)) {
        for (const keyInput of block?.inputs) {
          if (keyInput?.name === keyAutoFill) {
            setValue(`${blockName}.${keyAutoFill}`, valuesFromBankId[keyAutoFill])
          }
        }
      }
    }
  }, [valuesFromBankId, block.inputs])

  useEffect(() => {
    if (!bankIdData || Object.keys(bankIdData).length === 0) return
    const data: Record<string, any> = {}

    Object.entries(commonDataFieldNames).forEach(([key, value]: any) => {
      if (key === 'gender') {
        return bankIdData[value] === 'M' ? data[key] = 'Male' : data[key] = 'Female'
      }
      if (key === 'birth_date') {
        return data[key] = bankIdData[value]?.split('.').reverse().join('-')
      }
      data[key] = bankIdData[value]
    })

    const document = bankIdData?.documents?.find((doc: any) => doc.type === 'idpassport') ?? bankIdData.documents[0]

    if (document.type === 'idpassport' || document.type === 'passport') {
      Object.entries(documentsDataFieldNames).forEach(([keyName, value]: any) => {
        if (document?.type === 'passport' && keyName === 'identify_document_code') {
          return data[keyName] = document.series + document[value]
        }

        if (keyName === 'identify_document_issued_date') {
          return data[keyName] = document[value]?.split('.').reverse().join('-')
        }

        if (document[value]) {
          data[keyName] = document[value]
        } else {
          data[keyName] = bankIdData[value]
        }
      })
    } else {
      data.social_number = bankIdData[documentsDataFieldNames.social_number]
    }

    setValuesFromBankId(prev => ({ ...prev, ...data }))
  }, [block.id, bankIdData])

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" mb={2}>
        {block.title}
      </Typography>
      {sortedInputs.length ? (
        <Grid
          container
          spacing={block.inputs.length ? 3 : 0}
          mb={2}
          sx={{ alignItems: 'flex-end' }}
        >
          {sortedInputs.map(input => {
            const { id, name, type, styles, options, minLength, maxLength, question, isShowHiddenInput, hidden_input } = input
            // TODO: Type this data after first priority tasks
            const replaceName = name ? name?.replace(/[\s,\\()']/g, '_') : ''
            const replaceNameHiddenBlock = hidden_input?.name ? hidden_input?.name?.replace(/[\s,\\()']/g, '_') : ''
            const replaceQuestion = getQuestionName(input, currentLanguage) ? getQuestionName(input, currentLanguage)?.toLowerCase()?.replace(/[\s,\\()'/]/g, '_') : ''

            const displayHiddenInput = (() => {
              if (!isShowHiddenInput || !hidden_input) {
                return false
              }

              const parentInputValue = watch(`${blockName}.${replaceName}`)

              if (input.type === 'checkbox') {
                return parentInputValue === booleanFromString(hidden_input.secret_key)
              }

              return parentInputValue === hidden_input.secret_key
            })()

            return (
              <Grid
                item
                key={id}
                xs={isMobile ? 12 : styles.size}
                sx={{ alignSelf: type === 'file' ? 'stretch' : 'flex-end' }}
              >
                {['text', 'number', 'password', 'email'].includes(type) && (
                  <Controller
                    name={`${blockName}.${replaceName || replaceQuestion}`}
                    control={control}
                    defaultValue={''}
                    rules={{
                      required: t('formValidation.required'),
                      pattern: getPattern(type),
                      validate: {
                        checkMinLength: (value: string) => value.length >= (minLength ? +minLength : 1) || t('formValidation.minLength', { n: minLength ? +minLength : 1 }),
                        checkMaxLength: (value: string) => value.length <= (maxLength || 255) || t('formValidation.maxLength', { n: maxLength || 255 })
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <CustomTextInput
                          {...field}
                          input={input}
                          cError={error}
                          disabled={Boolean(valuesFromBankId[name])}
                          setCountClearInput={setCountClearInput}
                        />
                      )
                    }}
                  />
                )}

                {type === 'phone' && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{
                      required: t('formValidation.required'),
                      validate: {
                        format: v => isValidPhoneNumber(`+${v}`)
                      }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <CustomPhoneInput
                          {...field}
                          input={input}
                          cError={error}
                          disabled={Boolean(valuesFromBankId[name])}
                        />
                      )
                    }}
                  />
                )}

                {type === 'date' && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{ required: t('formValidation.required') }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang === 'uk' ? ukLocale : enLocale}>
                          <DatePicker
                            {...field}
                            disableFuture
                            disabled={Boolean(valuesFromBankId[name])}
                            renderInput={params => (
                              <CustomDateInput input={input} {...params} cError={error} disabled={Boolean(valuesFromBankId[name])}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )
                    }}
                  />
                )}

                {type === 'select' && options && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{ required: { value: true, message: t('formValidation.required') } }}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <CustomSelectInput cInput={input} {...field} cError={error} disabled={Boolean(valuesFromBankId[name])}
                        />
                      )
                    }}
                  />
                )}

                {type === 'file' && <CustomFileInput input={input} blockName={blockName} />}

                {type === 'checkbox' && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => {
                      return (
                          <CustomCheckboxInput input={input} {...field} disabled={Boolean(valuesFromBankId[name])}
                        />
                      )
                    }}
                  />
                )}

                {displayHiddenInput && hidden_input && (
                  <>
                    {['text', 'number', 'password', 'email'].includes(hidden_input.type) && (
                      <Controller
                        name={`${blockName}.${replaceNameHiddenBlock}`}
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: 'The field must be filled',
                          pattern: getPattern(hidden_input.type)
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <CustomTextInput {...field} input={hidden_input} cError={error} setCountClearInput={setCountClearInput} disabled={Boolean(valuesFromBankId[name])}
                            />
                          )
                        }}
                      />
                    )}

                    {hidden_input.type === 'phone' && (
                      <Controller
                        name={`${blockName}.${replaceNameHiddenBlock}`}
                        control={control}
                        rules={{
                          required: 'The field must be filled',
                          validate: {
                            format: v => isValidPhoneNumber(`+${v}`)
                          }
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <CustomPhoneInput {...field} input={hidden_input} cError={error} disabled={Boolean(valuesFromBankId[name])}
                            />
                          )
                        }}
                      />
                    )}

                    {hidden_input.type === 'date' && (
                      <Controller
                        name={`${blockName}.${replaceNameHiddenBlock}`}
                        control={control}
                        rules={{ required: 'The field must be filled' }}
                        render={({ field, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang === 'uk' ? ukLocale : enLocale}>
                            <DatePicker
                              {...field}
                              disableFuture
                              disabled={Boolean(valuesFromBankId[name])}
                              renderInput={params => {
                                return (
                                  <CustomDateInput input={hidden_input} {...params} cError={error} disabled={Boolean(valuesFromBankId[name])}
                                  />
                                )
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    )}

                    {hidden_input.type === 'select' && options && (
                      <Controller
                        name={`${blockName}.${replaceNameHiddenBlock}`}
                        control={control}
                        rules={{ required: { value: true, message: 'The field must be filled' } }}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <CustomSelectInput cInput={hidden_input} {...field} cError={error} disabled={Boolean(valuesFromBankId[name])}
                            />
                          )
                        }}
                      />
                    )}

                    {hidden_input.type === 'file' && <CustomFileInput input={hidden_input} blockName={blockName} />}

                    {hidden_input.type === 'checkbox' && (
                      <Controller
                        name={`${blockName}.${replaceNameHiddenBlock}`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => {
                          return (
                              <CustomCheckboxInput input={input} {...field} disabled={Boolean(valuesFromBankId[name])}
                            />
                          )
                        }}
                      />
                    )}
                  </>
                )}
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </Box>
  )
}
