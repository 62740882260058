import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { clientApi } from 'api/clientApi'
import {
  clientApplicationDataSelector,
  templateCurrentStepSelector
} from 'redux/reducers/constructors/formConstructor/state/selectors'

import { ConstructorBlock } from '../ConstructorBlock/ConstructorBlock'

interface IConstructorStepProps {
  bankIdData?: any
}
interface IStepData {
  elements: {
    [key: string]: any
  }
  step_doc: {
    id: number
    step: number
    file_name: string
    file: string
    elements: string
  }[]
}
const ConstructorStep: React.FC<IConstructorStepProps> = ({ bankIdData }) => {
  const { setValue } = useFormContext()
  const [countClearInput, setCountClearInput] = useState<{name: string, countClear: number, countPast: number, moreTwoSymbol: boolean}[]>([])

  const step = useSelector(templateCurrentStepSelector)
  const { currentStepData } = useSelector(clientApplicationDataSelector)

  const [autofillData, setAutofillData] = useState(null)

  useEffect(() => {
    if (currentStepData) {
      for (const [blockName, inputs] of Object.entries(currentStepData as IStepData)) {
        if (blockName === 'step_doc') {
          for (const fileData of inputs) {
            const fileElements = JSON.parse(fileData.elements)
            setValue(`__files.${fileElements.inputId}`, fileData)
          }

          continue
        }

        for (const [inputName, value] of Object.entries(inputs)) {
          setValue(`${blockName}.${inputName}`, value)
        }
      }
    }
  }, [currentStepData, setValue])

  useEffect(() => {
    const getAutofill = async () => {
      await clientApi.applicationForm.getAutofillAnswers().then((res) => {
        if (res.data?.[0]?.questionnaire?.[0]?.answered_on_questions) {
          setAutofillData(res.data?.[0]?.questionnaire?.[0]?.answered_on_questions)
        }
      })
    }

    getAutofill()
  }, [])

  if (!step) return null

  if (!step.blocks.length) return <Typography>Empty step...</Typography>
  return (
    <Box component="form">
      {step.blocks.map(block => {
        return <ConstructorBlock key={block.id} block={block} autofillData={autofillData} setCountClearInput={setCountClearInput} bankIdData={bankIdData}/>
      })}
    </Box>
  )
}

export default ConstructorStep
