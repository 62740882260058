import {
  FormControl,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
  Tooltip,
  SxProps
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import React, { forwardRef, ClipboardEvent } from 'react'
import { FieldError } from 'react-hook-form'

import { ICustomInput } from 'features/formConstructor/types'

import iconWarning from '../../../../../assets/images/icons/warning.png'

interface IProps {
  input: ICustomInput
  styles?: SxProps
  cError?: FieldError | undefined
  setCountClearInput?: (prev: any) => void
}

export type TCustomTextInputProps = IProps & OutlinedInputProps

export const CustomTextInput = forwardRef<HTMLDivElement, TCustomTextInputProps>(
  ({ setCountClearInput, input, styles, cError, ...props }, ref) => {
    const { type, question } = input
    const handleCountClearInput = (nameField: 'countClear' | 'countPast', moreTwoSymbol: boolean = false, sumValue: boolean = true, valueCount: number = 1) => {
      if (setCountClearInput) {
        setCountClearInput((prev: { name: string, countClear: number, countPast: number, moreTwoSymbol: boolean }[]) => {
          if (prev?.length > 0) {
            const findNameIndex = prev.findIndex((item) => item.name === props.name)
            if (findNameIndex !== -1) {
              if (sumValue) {
                prev[findNameIndex] = { ...prev[findNameIndex], [nameField]: prev[findNameIndex][nameField] + 1, moreTwoSymbol }
              } else {
                prev[findNameIndex] = { ...prev[findNameIndex], moreTwoSymbol }
              }
              localStorage.setItem('countClearInput', JSON.stringify(prev))
              return prev
            } else {
              if (nameField === 'countClear') {
                localStorage.setItem('countClearInput', JSON.stringify([...prev, { name: props.name, countClear: valueCount, countPast: 0, moreTwoSymbol }]))
                return [...prev, { name: props.name, countClear: valueCount, countPast: 0, moreTwoSymbol }]
              }
              if (nameField === 'countPast') {
                localStorage.setItem('countClearInput', JSON.stringify([...prev, { name: props.name, countClear: 0, countPast: valueCount, moreTwoSymbol }]))
                return [...prev, { name: props.name, countClear: 0, countPast: valueCount, moreTwoSymbol }]
              }
            }
          }

          if (nameField === 'countClear') {
            localStorage.setItem('countClearInput', JSON.stringify([{ name: props.name, countClear: valueCount, countPast: 0, moreTwoSymbol }]))
            return [{ name: props.name, countClear: valueCount, countPast: 0, moreTwoSymbol }]
          }
          if (nameField === 'countPast') {
            localStorage.setItem('countClearInput', JSON.stringify([{ name: props.name, countClear: 0, countPast: valueCount, moreTwoSymbol }]))
            return [{ name: props.name, countClear: 0, countPast: valueCount, moreTwoSymbol }]
          }
        })
      }
    }

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
      handleCountClearInput('countPast', true, true)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event)
      }

      if (event.target.value?.length > 2) {
        handleCountClearInput('countClear', true, false, 0)
      }

      const localCountClearInput = localStorage.getItem('countClearInput')

      if (localCountClearInput) {
        const parseLocalCountClearInput = JSON.parse(localCountClearInput)
        const findMoreTwoSymbol = parseLocalCountClearInput.find((item: { name: string, countClear: number, countPast: number, moreTwoSymbol: boolean }) => item.name === props.name)?.moreTwoSymbol

        if (event.target.value?.length === 0 && findMoreTwoSymbol) {
          handleCountClearInput('countClear', false)
        }
      }
    }
    return (
      <FormControl fullWidth sx={styles} error={!!cError}>
        <FormLabel>{question}</FormLabel>
        <OutlinedInput
          ref={ref}
          type={type}
          {...props}
          onChange={handleChange}
          onPaste={handlePaste}
          inputProps={{
            minLength: input?.minLength || 1,
            maxLength: input?.maxLength || 255
          }}
          endAdornment={
            cError && <Tooltip
              title={cError.message ?? ''}
              arrow
              TransitionComponent={Zoom}
              enterTouchDelay={0}
            >
              <span style={{ cursor: 'help' }}>
                <img
                  src={iconWarning}
                  alt="Warning icon"
                  style={{ width: '20px', pointerEvents: 'none' }}
                />
              </span>
            </Tooltip>
          }
        />
      </FormControl>
    )
  }
)

CustomTextInput.displayName = 'CustomTextInput'
